import { Component, OnInit } from '@angular/core';
import { Link } from 'src/shared/ng-models/Link.interface';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import { ReportV2Service } from 'src/libs/api2/report-v2/report-v2.service';
import { IDropDownOption, IReportsTypeDropDownOption } from 'src/shared/models/DropdownItem.interface';

@Component({
  selector: 'mi-cost-change-analysis',
  templateUrl: './cost-change-analysis.component.html',
  styles: [
    `
      .iframe-container {
        width: 100%;
        border: 0.1px solid lightgray;
        height: 100vh;
        padding: 0px;
        border-radius: 5px;
      }
    `
  ],
})

export class CostChangeAnalysisComponent implements OnInit {
  metaBaseDashboardId: number;
  tenantId: string = null;
  showLoader: boolean = true;
  missingReportPayload: string[] | string

  breadCrumbs: Link[] = [
    { label: "Dashboard", url: "/", internal: true },
    { label: "Cost Change Analysis", url: "/cost-change-analysis", internal: true }
  ];

  reportTypeOptions: IDropDownOption[] = [
    { key: "Cost Analysis", value: 'cost_analysis' },
    // { key: "Missing Invoice", value: 'missing_invoice' }
  ];

  selectedReportTypeOption = this.reportTypeOptions[0]

  formatForReportType: IReportsTypeDropDownOption[] = [
    { key: "Service Condensed", value: 'condensed', reportName: "Cost Change Analysis Summary", metaBaseDashboardId: '' },
    { key: "Service Detailed", value: 'detailed', reportName: "Cost Change Analysis", metaBaseDashboardId: '' },
    // { key: "Invoice Only", value: 'invoice_only', reportName: "Cost Change Analysis Invoice Only", metaBaseDashboardId: '' }
  ];

  selectedFormatForReportType = this.formatForReportType[0]

  comparisonTypes: IDropDownOption[] = [
    { key: "Billing Period", value: 'invoice' },
    { key: "Period of Performance Value", value: 'pop' },
  ];

  selectedComparisonType = this.comparisonTypes[0].value

  billingMonthOptions: IDropDownOption[] = this.generateBillingMonthOptions();

  selectedBillingMonthOptions: string
  selectedPreviousBillingMonthOptions: string

  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];

  constructor(private reportV2Service: ReportV2Service) { }

  ngOnInit(): void {
    console.log("here CostChangeAnalysisComponent frontEndSettings", this.frontEndSettings)
    console.log("here CostChangeAnalysisComponent billingMonthOptions", this.billingMonthOptions)
    // const defaultReport = this.frontEndSettings.find(report => report.reportName === this.selectedFormatForReportType.reportName);
    // console.log("here CostChangeAnalysisComponent defaultReport", defaultReport)

    // if (defaultReport) {
    //   this.metaBaseDashboardId = defaultReport.metabaseDashboardId;
    //   this.tenantId = defaultReport.tenantId;
    // } else {
    //   console.warn("Report with name 'Cost Change Analysis' not found.");
    // }

    this.defaultState()


  }


  defaultState() {
    const defaultReport = this.frontEndSettings.find(report => report.reportName === this.selectedFormatForReportType.reportName);
    console.log("here CostChangeAnalysisComponent defaultReport", defaultReport)
    if (defaultReport) {
      this.metaBaseDashboardId = defaultReport.metabaseDashboardId;
      this.tenantId = defaultReport.tenantId;
    } else {
      console.warn("Report with name 'Cost Change Analysis' not found.");
    }
    this.onBillingMonthChangesHandler(this.billingMonthOptions[0].value)
  }

  onReportTypeChangesHandler(selectedOption: string): void {
    console.log("here CostChangeAnalysisComponent onReportTypeChangesHandler", selectedOption)
    this.selectedReportTypeOption = this.reportTypeOptions.filter((reportTypeOption) => reportTypeOption.value === selectedOption)[0]
    console.log("here CostChangeAnalysisComponent onReportTypeChangesHandler this.selectedReportTypeOption", this.selectedReportTypeOption)
    if (selectedOption === this.reportTypeOptions[0].value) { // Cost Analysis
      this.selectedFormatForReportType = this.formatForReportType[0]
      const costChangeAnalysisSummaryDashboard = this.frontEndSettings.find(report => report.reportName === 'Cost Change Analysis Summary')
      console.log("here CostChangeAnalysisComponent costChangeAnalysisSummaryDashboard", costChangeAnalysisSummaryDashboard)
      this.metaBaseDashboardId = costChangeAnalysisSummaryDashboard.metabaseDashboardId
      this.missingReportPayload = []
    }
    // if (selectedOption === this.reportTypeOptions[1].value) { // Missing Invoice
    //   this.selectedFormatForReportType = undefined
    //   const costChangeAnalysisInvoiceOnlyDashboard = this.frontEndSettings.find(report => report.reportName === 'Cost Change Analysis Invoice Only')
    //   console.log("here CostChangeAnalysisComponent metaBaseDashboardId", costChangeAnalysisInvoiceOnlyDashboard)
    //   this.metaBaseDashboardId = costChangeAnalysisInvoiceOnlyDashboard.metabaseDashboardId
    //   this.missingReportPayload = "yes"
    // }

    console.log("here CostChangeAnalysisComponent onReportTypeChangesHandler this.selectedFormatForReportType", this.selectedFormatForReportType)
    this.getMetaBaseURL();
  }

  onBillingMonthChangesHandler(selectedOption: string): void {
    this.selectedBillingMonthOptions = this.billingMonthOptions.filter((billingMonthOption) => billingMonthOption.value === selectedOption)[0].value
    this.calculatePreviousMonth()
  }

  calculatePreviousMonth() {
    const [year, month] = this.selectedBillingMonthOptions.split(' ');
    const inputDate = new Date(Number(year), Number(month) - 1); // Create Date object

    // Move to the previous month
    inputDate.setMonth(inputDate.getMonth() - 1);

    // Get the year, month number, and short month name
    const previousYear = inputDate.getFullYear();
    const previousMonthNumber = String(inputDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits
    const previousMonthName = inputDate.toLocaleString('default', { month: 'short' });
    // Return in the desired format
    this.selectedPreviousBillingMonthOptions = `${previousYear} ${previousMonthNumber} (${previousMonthName})`;
    console.log("here CostChangeAnalysisComponent this.selectedPreviousBillingMonthOptions", this.selectedPreviousBillingMonthOptions)

    this.getMetaBaseURL();
  }

  onFormatChangesHandler(selectedOption?: string): void {
    console.log("here CostChangeAnalysisComponent onFormatChangesHandler selectedOption", selectedOption)
    this.selectedFormatForReportType = this.formatForReportType.filter((formatType) => formatType.value === selectedOption)[0]
    console.log("here CostChangeAnalysisComponent onFormatChangesHandler selectedFormatForReportType", this.selectedFormatForReportType)
    const selectedReportType = this.frontEndSettings.find(report => report.reportName === this.selectedFormatForReportType.reportName);
    console.log("here CostChangeAnalysisComponent onFormatChangesHandler selectedReportType", selectedReportType)
    

    if( selectedReportType.reportName === 'Cost Change Analysis Invoice Only'){
      this.missingReportPayload = ["yes", "no"]
    }else{
      this.missingReportPayload = []
    }

    this.metaBaseDashboardId = selectedReportType.metabaseDashboardId
    this.tenantId = selectedReportType.tenantId

    this.getMetaBaseURL();

  }


  onComparisonTypeChangesHandler(selectedOption?: string): void {
    console.log("here CostChangeAnalysisComponent onComparisonTypeChangesHandler selectedComparisonTypes", this.selectedComparisonType)
    console.log("here CostChangeAnalysisComponent onComparisonTypeChangesHandler selectedOption", selectedOption)
    this.selectedComparisonType = selectedOption
    this.getMetaBaseURL();
  }

  generateBillingMonthOptions(): IDropDownOption[] {
    const options: IDropDownOption[] = [];
    const endYear = 2024; // Target year
    const endMonth = 9; // September
    const currentDate = new Date();

    // Start from the current month
    currentDate.setMonth(currentDate.getMonth());

    // Generate options until September 2024
    while (currentDate.getFullYear() > endYear || (currentDate.getFullYear() === endYear && currentDate.getMonth() + 1 >= endMonth)) {
      const monthName = currentDate.toLocaleString('default', { month: 'short' }); // Abbreviated month name
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Two-digit month

      options.push({
        key: `${year} ${month} (${monthName})`,
        value: `${year} ${month} (${monthName})`,
      });

      currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
    }

    return options;
  }





  getMetaBaseURL(): void {

    console.log("here CostChangeAnalysisComponent getMetaBaseURL selectedBillingMonthOptions", this.selectedBillingMonthOptions)
    console.log("here CostChangeAnalysisComponent getMetaBaseURL selectedPreviousBillingMonthOptions", this.selectedPreviousBillingMonthOptions)
    console.log("here CostChangeAnalysisComponent getMetaBaseURL metaBaseDashboardId", this.metaBaseDashboardId)
    console.log("here CostChangeAnalysisComponent getMetaBaseURL selectedReportTypeOption", this.selectedReportTypeOption)
    console.log("here CostChangeAnalysisComponent getMetaBaseURL selectedFormatForReportType", this.selectedFormatForReportType)
    console.log("here CostChangeAnalysisComponent getMetaBaseURL selectedComparisonTypes", this.selectedComparisonType)

    this.showLoader = true;
    this.reportV2Service.getCostChangeAnalysisDetails(this.tenantId, this.metaBaseDashboardId, this.selectedPreviousBillingMonthOptions, this.selectedBillingMonthOptions, this.selectedComparisonType, this.missingReportPayload).then((res: string) => {
      const cleanUrl = res.split('#')[0];
      const modifiedUrl = `${cleanUrl}#bordered&titled=false`;
      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).setAttribute("src", modifiedUrl);

      document.getElementById(`metabase-iframe-${this.metaBaseDashboardId}`).addEventListener('load', () => {
        this.showLoader = false;
      });
    });
  }

}

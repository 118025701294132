<div *ngIf="metaBaseDashboardId; else loading">
  <mi-page-header title="Cost Change Analysis" [breadcrumbs]="breadCrumbs" [links]="headerLinks">
  </mi-page-header>

  <div class="container">
    <div class="row">
      <div class="col-sm d-flex">
        <div class="mr-2 ml-1">
          <label>Report Type</label>
          <select class="mt-1" (change)="onReportTypeChangesHandler($event.target.value)">
            <option *ngFor="let report of reportTypeOptions" [value]="report.value">
              {{ report.key }}
            </option>
          </select>
        </div>
        <div class="mr-2 ml-1">
          <label>Month</label>
          <select class="mt-1" (change)="onBillingMonthChangesHandler($event.target.value)">
            <option *ngFor="let billingMonth of billingMonthOptions" [value]="billingMonth.value">
              {{ billingMonth.key }}
            </option>
          </select>
        </div>
        <div class="mr-2" *ngIf="selectedFormatForReportType != undefined">
          <label>Format</label>
          <select class="mt-1" (change)="onFormatChangesHandler($event.target.value)">
            <option *ngFor="let format of formatForReportType" [value]="format.value">
              {{ format.key }}
            </option>
          </select>
        </div>
        <div class="mr-2">
          <label>Comparison Type</label>
          <select class="mt-1" (change)="onComparisonTypeChangesHandler($event.target.value)">
            <option *ngFor="let comparisonType of comparisonTypes" [value]="comparisonType.value">
              {{ comparisonType.key }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="iframe-container mt-3">
    <mi-opacity-loader [ready]="!showLoader">
      <iframe class="iframe-container" [id]="'metabase-iframe-' + metaBaseDashboardId" src="" title="metabase">
      </iframe>
    </mi-opacity-loader>
  </div>
</div>
<ng-template #loading>
  <mi-loading-animation></mi-loading-animation>
</ng-template>
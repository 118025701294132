import {Component, OnInit} from "@angular/core";
import { Store } from "@ngrx/store";
import * as SDReducer from '../state/reducers';
import { AbstractPageComponent } from "src/shared/pages/abstract-page/abstract-page.component";
import { MiServiceDetails } from "src/shared/models/MiService";

@Component({
  selector: 'mi-service-details-header',
  templateUrl: './service-details-header.component.html',
  styleUrls: ['./service-details-header.component.scss']
})

export class ServiceDetailsHeaderComponent extends AbstractPageComponent implements OnInit{

  serviceDetails: MiServiceDetails;

  constructor(
    private store: Store<SDReducer.State>) {
    super();
  }

  ngOnInit() {
      this.store
      .select(SDReducer.getDSData)
      .takeUntil(this.destroy$)
      .subscribe(
        data => {
          if (data.serviceDetails) {
            this.serviceDetails = data.serviceDetails
          }
        }
      );
  }
  // Nothing.
}

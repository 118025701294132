import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractPageComponent } from '../../../pages/abstract-page/abstract-page.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';

@Component({
  selector: 'mi-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})

export class SearchHeaderComponent extends AbstractPageComponent {

  @Input() searchPlaceholder: string = 'Search';
  @Input() buttonUrl: string;
  @Input() searchType: string = 'VENDOR';
  @Input() total: number;
  @Output() searchParamChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() showButton: boolean = true
  searchString: Subject<string> = new Subject<string>();
  searchParamString: string;
  @Output() userCreated = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.searchString.pipe(debounceTime(1000))
      .subscribe(param => {
        this.searchParamChanged.emit(param);
      });
  }

  onUserCreated(newUser: TenantUser): void {
    this.searchParamString = newUser.email;
    this.searchParamChanged.emit(this.searchParamString);
  }

  onSearchSubmit(searchValue) {
    this.searchString.next(searchValue.toLowerCase().replace(/^\s+|\s+$/gm, '') || '');
  }

  clearSearch() {
    this.searchString.next('');
  }


}
<div class="search-header">
  <div class="clear-fix">
    <div class="search-col">
      <mi-search [placeholder]="searchPlaceholder" type="" [value]="searchParamString" (clearSearch)="clearSearch()"
        (searchSubmit)="onSearchSubmit($event)"></mi-search>
    </div>

    <div class="dropdown-col" *ngIf="showButton">
      <mi-header-action-dropdown (userCreated)="onUserCreated($event)" [searchType]="searchType" [addNewURL]="buttonUrl">
        <ng-content>
        </ng-content>
      </mi-header-action-dropdown>
    </div>
  </div>

  <div class="clear-fix">
    <div class="search-col">
      <div class="count">
        {{ ('SEARCH_QUANTITIES.' + searchType) | translate:{count:total} |
        translateSelector:total }}
      </div>
    </div>
  </div>
</div>
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import * as ASActions from '../../../../shared/state/advanced-search/actions';
import {Store} from '@ngrx/store';
import * as ASReducer from '../../../../shared/state/advanced-search/reducers';
import {AbstractPageComponent} from '../../../pages/abstract-page/abstract-page.component';
import { ModalService } from '@independer/ng-modal';
import { CreateReportOrViewModalComponent } from './create-report-or-view-modal/create-report-or-view-modal.component';
import { RunReportOrViewModalComponent } from './run-report-or-view-modal/run-report-or-view-modal.component';
import { ExcelEmailNotificationModalComponent } from '../../excel-email-notification-modal/excel-email-notification-modal.component';
import { AdvancedSearchFilterModalComponent } from '../../advanced-search-filter-modal/advanced-search-filter-modal.component';
import { TenantUser } from 'src/libs/api2/types/TenantUser.interface';
import { UsersManagementFormComponent } from '../../users-management-form/users-management-form.component';
import * as appDataReducer from "../../../state/app-data/reducers";
import { Tenant } from 'src/shared/models/Tenant';
import { AddressV2Service } from 'src/libs/api2/address-v2/address-v2.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mi-header-action-dropdown',
  templateUrl: './action-dropdown-buttons.component.html',
  styleUrls: ['./action-dropdown-buttons.component.scss']
})

export class HeaderActionDropdownComponent extends AbstractPageComponent {

  @Input() searchType: string = 'SERVICE';
  @Input() addNewURL: string = '';
  @ViewChild('addUserModal') addUserModal;
  userId: string = '';
  @ViewChild(UsersManagementFormComponent) usersManagementFormComponent!: UsersManagementFormComponent;
  @Output() userCreated = new EventEmitter<TenantUser>();


  isExcelDataLoading: boolean = false;
  addressToDownloadExcel: any[] = [];
  error: string;
  tenantData: Tenant;
  getTenantAddressesToDownloadPageNumber: number = 0;
  allDownloadedAddress: any[] = [];
  total = 0;
  total_mrc = 0;
  loading = true;
  q: string = '';

  constructor(
    private modalService: ModalService,
    private tenantAddressV2Service: AddressV2Service,
    private toastrService: ToastrService,
    private store: Store<ASReducer.State>) {
    super();
  }

  openAddUserModal() {
    this.addUserModal.open();
  }

  onUserUpdated(user: TenantUser): void {
    this.userCreated.emit(user);
    this.addUserModal.close();
  }

  close() {
    this.addUserModal.close();
    this.usersManagementFormComponent.resetForm();
  }

  onSearchSubmit(searchValue) {

    const newQuery = {
      q: searchValue
    };

    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));

  }

  clearSearch() {
    const newQuery = {
      q: ''
    };
    this.store.dispatch(new ASActions.AsFilterUpdate(newQuery));
  }

  ngOnInit() {
    const tenantStateData = this.store.select(appDataReducer.getAppData);
    tenantStateData.first().subscribe(
      (data) => this.tenantData = data.tenantData,
      (e) => (this.error = e)
    );
  }

  openRunModal(action_type: string = "SERVICE_REPORT"){

    const modalRef = this.modalService.open(RunReportOrViewModalComponent, m => {
      m.action_type = action_type;
      m.searchType = this.searchType;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;
      console.log("report", report)
      if(report && report.print && action_type === "SERVICE_REPORT"){
        this.createNotification("SERVICE_REPORT");
      }

    });
  }

  emailNotification(){
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Email Notification";
      m.message = "Your request to download Services Inventory is in progress. Please check your email in a few minutes to receive the requested information (it may also be in your Spam folder).";
      m.closeButtonLabel = "Confirm";
    });
  }

  createNotification(action_type){
    const modalRef = this.modalService.open(ExcelEmailNotificationModalComponent, m=> {
      m.title = "Create Notification";
      m.message = `Your request to create ${action_type} was successful. Please check Actions to use it in the future.`;
      m.closeButtonLabel = "Confirm";
    });
  }

  openCreateModal(action_type: string = "SERVICE_REPORT"){
    const modalRef = this.modalService.open(CreateReportOrViewModalComponent, m => {
      m.action_type = action_type;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results
      const [report] = result;

      if(report && report.report){
        this.createNotification(action_type);
      }

    });
  }

  openShowAllFilter(){
    const modalRef = this.modalService.open(AdvancedSearchFilterModalComponent, m => {
      m.searchType = ASReducer.ADVANCED_SEARCH_TYPES.TENANT_USER;
    });

    modalRef.closed.subscribe(({reason, result = []}) => {
      //results come in array, as the modal supports multiple returned results

      const [data] = result;
      if(data){
        console.log(data);
      }

    });
  }

  filterObjectArray(data: any[]): any[] {
    // Create a Map to store unique entries based on `id`
    const uniqueDataMap = new Map();
  
    data.forEach(({ id, street_address, state_id, country_id, google_place_id, owner, locations, ...filtered }) => {
      if (!uniqueDataMap.has(id)) {
        uniqueDataMap.set(id, filtered);
      }
    });
  
    // Return the unique values as an array
    return Array.from(uniqueDataMap.values());
}

getTenantAddressesToDownload() {
    if (this.getTenantAddressesToDownloadPageNumber === 0) {
      this.toastrService.info(
        `Please wait we are generating Addresses excel.`,
        '',
        { timeOut: 8000 } // Set the duration to 8 seconds
      );
    }

    this.isExcelDataLoading = true;
    this.tenantAddressV2Service
      .getAllAddressByOwner(this.tenantData.domain, '', this.getTenantAddressesToDownloadPageNumber, 200, "cmrc")
      .toPromise()
      .then(data => {
        console.log("here getTenantAddressesToDownload data", data);
        this.allDownloadedAddress.push(...data.data);
        if (data.moreAvailable) {
          console.log("here getTenantAddressesToDownload this.allDownloadedAddress", this.allDownloadedAddress);
          this.getTenantAddressesToDownloadPageNumber = this.getTenantAddressesToDownloadPageNumber + 1;
          this.getTenantAddressesToDownload();
        } else {
          console.log("here getTenantAddressesToDownload this.allDownloadedAddress before modify", this.allDownloadedAddress);
          const modifiedData = this.filterObjectArray(this.allDownloadedAddress);
          console.log("here getTenantAddressesToDownload modifiedData", modifiedData);
          this.isExcelDataLoading = false;
          this.downloadAddresses(modifiedData);
        }
      })
      .catch((e) => {
        this.error = e;
        this.isExcelDataLoading = false;
        this.toastrService.success(`Facing some issue while downloading addresses`);
      });
}

downloadAddresses(modifiedData): void {
    const renamedData = modifiedData.map((item: any) => {
      const renamedItem: any = { ...item };
  
      if (renamedItem.formatted_address) renamedItem.Address = renamedItem.formatted_address;
      if (renamedItem.state_name) renamedItem.State = renamedItem.state_name;
      if (renamedItem.country_name) renamedItem.Country = renamedItem.country_name;
      if (renamedItem.city || renamedItem.city_name) {
        renamedItem.City = renamedItem.city || renamedItem.city_name;
      }
      if (renamedItem.location_count) renamedItem['Location Count'] = renamedItem.location_count;
      if (renamedItem.service_count) renamedItem['Service Count'] = renamedItem.service_count;
  
      // Format c_mrc with commas (remove dollar sign)
      if (renamedItem.c_mrc !== undefined && renamedItem.c_mrc !== null) {
        renamedItem['Monthly Spend'] = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(renamedItem.c_mrc);
      }
  
      if (renamedItem.latitude) renamedItem.Latitude = Number(renamedItem.latitude).toFixed(4);
      if (renamedItem.longitude) renamedItem.Longitude = Number(renamedItem.longitude).toFixed(4);
      if (renamedItem.zip) renamedItem.Zip = renamedItem.zip;
  
      return renamedItem;
    });
  
    const columnOrder = [
      'Address',
      'City',
      'State',
      'State Other',
      'Zip',
      'Country',
      'Latitude',
      'Longitude',
      'Monthly Spend',
      'Location Count',
      'Service Count',
    ];
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Addresses');
  
    worksheet.columns = columnOrder.map((key) => ({ header: key, key }));
  
    renamedData.forEach((item: any) => {
      const row: any = {};
      columnOrder.forEach((key) => {
        row[key] = item[key] !== undefined && item[key] !== null ? item[key] : null; // Preserve 0 values
      });
      worksheet.addRow(row);
    });
  
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, size: 11 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9E1F2' },
      };
    });
  
    worksheet.columns.forEach((column) => {
      let maxLength = 10;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    });
  
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];
    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: renamedData.length + 1, column: columnOrder.length },
    };
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      const date = new Date();
      const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${date.getFullYear()}`;
      const filename = `addressdownload_${this.tenantData.v2.domain}_${formattedDate}.xlsx`;
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(data, filename);
    });
}

  
  
  


}
